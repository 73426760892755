
import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Vue} from 'vue-property-decorator';
import {services} from '@/main';
import PatternUtils from '@/utils/PatternUtils';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import AlertService from '@/utils/AlertService';
@Component({
  components: {SigInputTexte},
})
export default class MotDePasseOublie extends AbstractComponentVue {
	// pour contenir l'email
	private email: string = '@notaires.fr';
	private emailState: boolean = false;
	private patternUtils = PatternUtils;
	private failureMessage: string = 'Adresse e-mail inconnue ou inactive. Veuillez saisir votre adresse de messagerie @notaires.fr, @paris.notaires.fr ou votre adresse de secours saisie dans vos informations personnelles.';
  /**
   * fonction pour submit
   */
  private submitForgottenPassword(): void {
    if ( this.emailState ) {
      // appel REST pour le mdp oublie
      services.restService
        .callPutWithContentType('/api/v1/tokens/forgotten-password', { "email": this.email } , 'application/json;charset=UTF-8')
        .then( (resp: any) => {
          const successMessage = 'Un email contenant les informations détaillées pour réinitialiser votre mot de passe a été envoyé à l\'adresse ' + this.email;
          AlertService.success(successMessage);
          this.gotoHome();
        })
        .catch( (resp) => {
          AlertService.error(this.failureMessage);
        });
    }
  }

	private gotoHome() {
		this.$router.push('/');
	}

	private changeEmailState(inputDto: ISigInputTextDto) {
		this.emailState = !inputDto.hasError;
		this.email = inputDto.value;
	}
}
